@import url('https://fonts.googleapis.com/css2?family=Just+Another+Hand&family=Rock+Salt&display=swap');

.pg-sec{
    position: relative;
    width: 100vw;
    background-image: url('../assets/imgs/aerial-view-of-buildigns-1642125.jpg');
    background-size: cover;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 100vh;
    

}

.pg-sec.purple{
    background: rgb(189, 69, 189);
}
.v-container {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    
}
.fnl-nv {
    width: 100%;
    padding: 25px 0 10px 0;
    background: rgb(158, 75, 236);
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 1px 1px 3px black;
    height: 20%;
}

.bg-opaque{
    position: relative;
    width: 90%; 
    height: 80%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    flex: 1;
    padding-bottom: 25px;
}

.bg-opaque::before{
    position: absolute;
    top: 0;
    left: 0;
    background: rgba(255, 255, 255, 0.658);
    content: '';
    width: 100%;
    height: 100%;
    filter: blur(4px)
    
}

.v-header {
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.726);
    height: 20%;
    width: 110%;
    position: relative;
    font-size: 35px;
    border: 4px solid black;
    /* border-bottom: 4px solid rgb(99, 21, 172); */
    border-top: unset;
    text-shadow: 1px 2px 1px black;
    font-weight: 600;
    color: white;
}
.hdr-ctr{
    display: flex;
    margin: 15px 5px;
}

.rgs-ctr{
    display: flex;
    justify-content: flex-start;
    position: relative;
    background: rgb(79, 17, 138);
    border-top-right-radius: 10px ;
    padding-right: 8px;
    border-bottom-left-radius: 10px;
    box-shadow: 1px 1px 2px black;
}



.register{
    color: rgb(226, 226, 226);
    text-shadow: 1px 1px 1px black;
}

.stabilize{
    color: rgb(180, 160, 47);
    font-weight: 700;
    text-shadow: 1px 1px 1px black;
}

.anticon-caret-right{
    display: flex;
    align-items: center;
    justify-content: right;
    position: relative;
    right: -4%;
}
.btm{
    position: relative;
    width: 100%;
    display: flex;
    height: 80%;
    justify-content: center;
    align-items: flex-end;

}


.btm-sec{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50%;
    height: 100%;
    color: white;
}
.acs-ctr{
    position: relative;
    display: flex;
    flex-direction: column;
    width: 85%;
    height: 90%;
    min-width: 320px;
    background: black;
    align-items: center;
}
.acs-ctr.r{
    background: unset;
    height: 100%;
}

.acs-hdr{
    border-bottom: 1px solid whitesmoke;
    width: 100%;
    color: white;
    font-size: 25px;
    margin-bottom: 5px;
}

.acs-hdr2{
    border-bottom: 1px solid darkslategrey;
    width: 100%;
    color: black;
    font-size: 25px;
    margin-bottom: 5px;
    /* font-family:  'Rock Salt', cursive; */
}

.mr-inf{
    color: black;
    width: 100%;
    display: flex;
    justify-content: center;
    font-weight: 600;
    margin: 5px 0;
}

.acs-hdr2 > span{
    font-family:  'Rock Salt', cursive;
    font-size: 18px;
}

.acs-spt{
    font-size: 18px;
    color: white;
}

.acs-spt > span {
    color: rgb(158, 75, 236);
    font-weight: bold;
}

.acs-dsc{
    display: flex;
    justify-content: center;
    font-size: 14px;
    text-align: center;
    width: 88%;
    font-weight: 500;
    border: 1px inset white;
    padding: 8px;
}

.acs-lst{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 15px 0;
    width: 100%;
}

.acs-itm{
    width: 100%;
    display: flex;
    font-size: 15px;
    text-align: left;
    margin-bottom: 8px;

}

.acs-itms{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 15px;
}

.acs-side{
    display: flex;
    position: relative;
    width: 5%;
    height: 100%;
    margin-right: 15px;

}


.l-bar{
    position: absolute;
    right: 15px;
    width: 2px;
    background: rgb(99, 21, 172);
    height: 100%;
}

.r-bar{
    position: absolute;
    right: 0;
    width: 10px;
    background: rgb(99, 21, 172);
    border-top-right-radius: 10px;
    height: 100%;
}
.i-bar{

}

.acs-icon{
    margin-right: 5px;
}

.input-ctr{
    display: flex;
    width: 100%;
    margin: 15px 0;
}
.titles{
    width: 30%;
    display: flex;
    font-size: 15px;
    font-weight: 700;
}
.input{
    width: 70%;
    position: relative;
    
}

.input > input, select{
    width: 80%;
    padding: 5px;
}


input::placeholder{
    font-size: 12px;
    color: gray;
    font-style: italic;
}
.form {
    width: 90%;
    color: black;
    background: white;
    border-radius: 20px;
    border-right: 15px solid rgb(255, 51, 24);
    border-left: 15px solid rgb(255, 51, 24);
    padding: 10px;
    box-shadow: 1px 1px 3px black;
}

.suggestions-ctr{
    position: absolute;
    display: flex;
    flex-direction: column;
    max-height: 100px;
    overflow-y: scroll;
    z-index: 3;
    background: white;
    right: 9%; 
    width: 80%;
}

.suggestions-ctr.hidden{
    display: none;
}
.suggestions-ctr > .option:hover{
    background: silver;
    color: rgb(255, 51, 24);
    cursor: pointer;
    
}
.suggestions-ctr > .option {
    font-size: 14px;
    width: 100%;
}

.box{
    position: absolute;
    top: -11%;
    left: -4%;
    height: 35%;
    z-index: 1;
    width: 11%;
    background: black;
}

.ot-box{
    border: 1px solid gainsboro;
    position: absolute;
    top: -6%;
    left: -2%;
    height: 82%;
    width: 37%;
    z-index: 1;
}

.box.l{
    background: whitesmoke;
    display: none;

}

.submit{
    font-size: 16px;
    color: white;
    background: rgb(255, 51, 24);
    border: 3px inset rgb(255, 51, 24);
    border-radius: 5px;
    cursor: pointer;

}

.submit:hover{
    color: rgb(255, 51, 24);
    background: white;
}

.submit.inactive{
    pointer-events: none;
    color: rgb(158, 158, 158);
    background: gray;
}

.submit-ctr{
    display: flex;
    justify-content: center;
    margin-top: 10px;
}

.v-txt{
    position: relative;
    color: black;
    font-size: 40px;
    text-shadow: 1px 1px 1px black;
    background: rgb(99, 21, 172);
    padding: 35px 15px;
    border-bottom-left-radius: 40px;
    box-shadow: 1px 1px 3px black;
    z-index: 5;
    width: 100%;
}


.md-txt{
    color: rgb(248, 243, 239);
}

.ty-sbt{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 300px;
    flex-direction: column;
}

.ty-hdr{
    font-family: "Rock Salt", cursive;
    text-shadow: 1px 1px 1px green;
}

.ty-txt{
    font-size: 20px;
    font-family: calibri;
    font-weight: 600;
    text-align: center;
    max-width: 600px;
}

@media screen and (max-width: 1200px){
    .acs-hdr2 {
        font-size: 18px;
    }
}

@media screen and (max-width: 850px){
    .hdr-ctr{
        font-size: 30px;
    }
}

@media screen and (max-width: 750px){
    .v-header{
        flex-direction: column;
        
    }
    .hdr-ctr.bg{
        background: rgba(255, 255, 255, 0.164);
    }
    .btm{
        flex-direction: column;
        align-items: center;
    }

    .hdr-ctr{
        display: flex;
        justify-content: center;
        margin:0;
        padding: 10px;
        width: 100%;
    }


    .btm-sec{
        width: 100%;
    }
    .bg-opaque{
        width: 95%;
    }
    .acs-hdr2{
        font-size: 14px;
    }
    .titles{
        font-size: 13px;
    }
    .acs-ctr{
        width: 95%;
    }
}

@media screen and (max-width: 450px){
    .acs-spt > span {
        font-weight: 700;
    }
    .v-txt{
        font-size: 28px;
    width: 100%;     
    }
    .acs-hdr2{
        font-size: 18px;
    }
    .v-header{
        width: 100%;
    }
    .btm-sec{
        margin: 25px 0;
    }
    .hdr-ctr{
        font-size: 27px;
    }

    .input-ctr{
        flex-direction: column;
        align-items: center;
    }

    .input{
        width: 100%;
    }
    .titles{
        width: 100%;
        display: flex;
        justify-content: center;
        font-size: 21px;
    }
    
}
